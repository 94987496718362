<template>
  <!-- Image Carousel -->
  <!-- change to 6500 6.5 seconds when ready to publish-->
  <Carousel :autoplay="6500" :wrap-around="true">
    <Slide v-for="(slide, index) in carouselSlides" :key="index">
      <div class="carousel-inner">
        <img
          :src="require(`../assets/img/carousel/${slide.img}`)"
          alt=""
          class="w-100"
        />
        <div class="carousel-caption">
          <div class="container">
            <span v-html="slide.template"></span>
            <!--carouselSlides-->
          </div>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation>
        <template #prev>
          <span class="fas fas fa-chevron-left fa-2x"></span>
        </template>
        <template #next>
          <span class="fas fa-chevron-right fa-2x"></span>
        </template>
      </Navigation>

      <!-- <Pagination /> -->
    </template>
  </Carousel>
  <span class="fas fa-chevon-left"></span>

  <!-- Main Page Heading -->
  <div class="col-12 text-center mt-5">
    <h1 class="text dark pt-4">How Tos and Products</h1>
    <div class="border-top border-primary w-25 mx-auto my-3"></div>
    <p class="lead">For all the champions in this world.</p>
  </div>

  <!-- Three Column Section -->
  <div class="container">
    <div class="row my-5">
      <div class="col-md-4 my-4">
        <img :src="require(`../assets/img/home/software.jpg`)" alt="" class="w-100" />
        <h4 class="my-4">Software Development</h4>
        <p>
          How to build high performance websites - building software with quality -
          top to bottom: HTML5, CSS, JavaScript, Vue.js, Python Fast API, Docker & Mongo.
        </p>
        <a href="#" class="btn btn-outline-dark btn-md">Getting Started</a>
      </div>

      <div class="col-md-4 my-4">
        <img :src="require(`../assets/img/home/computer_hardware.png`)" alt="" class="w-100" />
        <h4 class="my-4">Choosing Computer Hardware</h4>
        <p>
          How can I be sure to buy the right Chromebook, Laptop, PC, Android Phone, or Microcomputer device.
        </p>
        <a href="#" class="btn btn-outline-dark btn-md">Down the rabbit hole</a>
      </div>

      <div class="col-md-4 my-4">
        <img :src="require(`../assets/img/home/3d_printing.png`)" alt="" class="w-100" />
        <h4 class="my-4">Starting 3D Printing</h4>
        <p>
          Where do I start with 3D Printing, and where is 3D technology trending too.
        </p>
        <a href="#" class="btn btn-outline-dark btn-md">How to succeed</a>
      </div>
    </div>
  </div>

  <!-- Fixed Background IMG -->
  <!-- Emoji Navbar First -->
  <a
    class="navbar bg-primary sticky-top emoji"
    href="#emoji"
    role="button"
    data-bs-toggle="collapse"
  ></a>

  <div class="fixed-background">
    <div class="row text-light py-5">
      <div class="col-12 text-center">
        <h1>Advance to the next level</h1>
        <h3 class="py-4">..see what's on the other side.</h3>
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modalBlue"
          class="btn btn-primary btn-lg me-2"
        >
          Blue Pill
        </button>
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modalRed"
          class="btn btn-danger btn-lg ms-2"
        >
          Red Pill
        </button>
      </div>
    </div>
    <div class="fixed-wrap">
      <div class="fixed"></div>
    </div>
  </div>

  <!-- Emoji Navbar Second -->
  <a
    class="navbar bg-primary sticky-top emoji text-center"
    href="#emoji"
    role="button"
    data-bs-toggle="collapse"
  >
    <i class="fas fa-plug"></i>
  </a>

  <div class="collapse" id="emoji">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <iframe src="https://giphy.com/embed/ltIFdjNAasOwVvKhvx" width="150" height="150" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        </div>
        <div class="col-sm-6 col-md-3">
          <iframe src="https://giphy.com/embed/qgQUggAC3Pfv687qPC" width="150" height="150" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        </div>
        <div class="col-sm-6 col-md-3">
          <iframe src="https://giphy.com/embed/N5pbp1IJrGtgWseUJb" width="150" height="150" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        </div>
        <div class="col-sm-6 col-md-3">
          <iframe src="https://giphy.com/embed/y0NFayaBeiWEU" width="150" height="150" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center ">
          Emojies by: GIPHY  
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Popup -->
  <div class="modal fade" id="modalBlue">
    <div class="modal-dialog">
      <iframe src="https://giphy.com/embed/SxoMtutEA8gKLsJVvE" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
    </div>
  </div>
  <div class="modal fade" id="modalRed">
    <div class="modal-dialog">
      <iframe src="https://giphy.com/embed/NhYOzZQVbxT6vy5CFo" width="480" height="264" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
    </div>
  </div>

  <!-- Two Column Section -->
  <div class="container my-5">
    <div class="row py-4">
      <div class="col-lg-4 mb-4 my-lg-auto">
        <h1 class="text-dark font-weight-bold mb-3">
          We've been expecting you
        </h1>

        <p class="mb-4">
          At our core is a collection of topics and products to help with 
          success in modern times.
        </p>
        <a
          href="https://w3newbie.com/themes/"
          target="_blank"
          class="btn btn-outline-dark btn-lg"
          >Our Mission</a
        >
      </div>
      <div class="col-lg-8">
        <img
          :src="require(`../assets/img/home/code.jpg`)"
          alt=""
          class="w-100"
        />
      </div>
    </div>
  </div>

  <!-- Jumbotron -->
  <div class="p-5 bg-light mb-0">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-8 col-xl-9 my-auto">
          <h4>Looking for quality software development or hardware services?</h4>
        </div>
        <div class="col-md-5 col-lg-4 col-xl-3 pt-4 pt-md-0">
          <a href="#" class="btn btn-primary btn-lg">Contact us today</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
//import MainComp from '@/components/MainComp.vue'

import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

//import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "HomeView",
  components: {
    Carousel,
    Slide,
    //Pagination,
    Navigation,
  },
  setup() {
    const carouselSlides = [
      {
        img: "software_2.jpg",
        template:
          "<div class='row justify-content-center'> \
                    <div class='col-8 bg-custom d-none d-md-block py-3 px-0'> \
                        <h1>Top Quality</h1> \
                        <div class='border-top border-primary w-50 mx-auto my-3'></div> \
                            <h3 class='pd-3'>Software Development</h3> \
                            <a href='#' class='btn btn-danger btn-lg me-2'>Web Services</a> \
                            <a href='#' class='btn btn-primary btn-lg ms-2'>Video Games</a> \
                        </div> \
                    </div> \
                </div>",
      },
      {
        img: "pc_hardware.jpg",
        template:
          "<div class='row justify-content-end text-end'> \
                        <div class='col-5 bg-custom d-none d-md-block py-3 px-0 pe-3'> \
                            <h3 class='pb-3'>Computer Hardware</p> \
                            <p class='lead pb-3'>Laptop PC and Micro boards.</p> \
                            <a href='#' class='btn btn-danger btn-lg me-2'>Learn More</a> \
                        </div> \
                    </div> \
                </div>",
      },
      {
        img: "3D_printing.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>3D Printing</h3> \
                        <p class='lead pb-3'>The Best Functional and Hobby Prints.</p> \
                        <a href='#' class='btn btn-primary btn-lg pb-3'>See Products</a> \
                    </div> \
                </div>",
      },
      {
        img: "gaming.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>Video Games</h3> \
                        <p class='lead pb-3'>Roblox and Godot</p> \
                        <a href='#' class='btn btn-danger btn-lg me-2'>Aurora Realms</a> \
                        <a href='#' class='btn btn-primary btn-lg ms-2'>Asteroid Blaster: Space Ship Fighter Duel</a> \
                    </div> \
                </div>",
      },
      {
        img: "radio_control.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>Radio Control Devices</h3> \
                        <p class='lead pb-3'>Quadcopters to cars and everything in between</p> \
                        <a href='#' class='btn btn-danger btn-lg me-2'>Radios & Electronics</a> \
                        <a href='#' class='btn btn-primary btn-lg ms-2'>Vehicles</a> \
                    </div> \
                </div>",
      },
      {
        img: "snorkleing.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>Florida Snorkeling</h3> \
                        <p class='lead pb-3'>Maps to Reefs in South Florida</p> \
                        <a href='#' class='btn btn-danger btn-lg me-2'>Find a Reef Nearby</a> \
                    </div> \
                </div>",
      },
      {
        img: "windsurfing.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>Windsurfing: Back to Basics</h3> \
                        <a href='#' class='btn btn-danger btn-lg me-2'>Sailing How To</a> \
                    </div> \
                </div>",
      },
      {
        img: "Physical_therapy.jpg",
        template:
          "<div class='row justify-content-start text-start'> \
                    <div class='col-7 bg-custom d-none d-md-block py-3 px-0 ps-3'> \
                        <h3 class='pb-3'>Being Parapaligic</h3> \
                        <a href='#' class='btn btn-danger btn-lg me-2'>How Tos</a> \
                        <a href='#' class='btn btn-primary btn-lg ms-2'>Exersizes</a> \
                    </div> \
                </div>",
      },         
      
    ];
    return { carouselSlides };
  },
});
</script>

<style scoped>
  @import "@/assets/css/carousel.css";
  @import "@/assets/css/fixed-background.css";
  @import "/node_modules/vue3-carousel/dist/carousel.css";

</style>
