<template>
  <!-- Footer -->
  <footer>
    <div class="container">
      <div class="row text-light text-center py-4 justify-content-center">
        <div class="col-sm-10 col-md-8 col-lg-6">
          <img :src="require(`../assets/img/logo.png`)" alt="">
          <p>At our core is a collection of software development and hardware solutions
            that represent everything your business needs to compete in the modern marketplace.
          </p>
          <ul class="social pt-3">
            <li><a href="#" target="_blank"><i class="fab fa-facebook"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
            <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
          </ul>

        </div>
      </div>
    </div>
  </footer>
  <!-- Socket -->
  <div class="socket text-light text-center py-3">
    <p>&copy; <a href="#" target="_blank">AJSurf.net</a></p>
  </div>

</template>
  
<script>
  export default {
    name: 'FooterComp',
    props: {
      msg: String
    }
  }
</script>
  
<!-- "scoped" limits CSS to this component only -->
<style scoped>
    @import "@/assets/css/footer.css";
</style>
  